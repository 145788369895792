import HttpService from "./HttpService";

class NotificationService extends HttpService {
  async updateDeviceToken(payload) {
    return await this.sendRequest({
      method: "PATCH",
      url: `/auth/fcb-token`,
      responseType: "json",
      data: payload,
    });
  }
}

const notificationService = new NotificationService();
export default notificationService;
