import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined, LoadingOutlined } from "@ant-design/icons";
import authService from "../../../services/AuthService";
import { openNotificationWithIcon } from "../../../utils/handler";
import { useNavigate } from "react-router-dom";

function ForgetPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    authService
      .forgetPassword(values)
      .then((response) => {
        if (response) {
          setLoading(false);
          openNotificationWithIcon("success", "Email sent successfully");
        }
      })
      .catch((error) => {
        openNotificationWithIcon("error", error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="normal-login-wrapper">
      <div className="page-normal-login">
        <Form
          name="normal_login"
          className="forget-pass-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label="Please enter your email"
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={loading}
            >
              {loading && <LoadingOutlined style={{ fontSize: 16 }} spin />}
              Send
            </Button>
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button type="link" onClick={() => navigate("/")}>
              Back To Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ForgetPassword;
