import React, { Suspense } from "react";
import { Layout } from "antd";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { Loading3QuartersOutlined } from "@ant-design/icons";

import { Outlet } from "react-router-dom";

const { Content } = Layout;

function AppContent() {
  const auth = useSelector((state) => state);

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 280,
      }}
    >
      <Suspense
        fallback={
          <Loading3QuartersOutlined
            style={{ fontSize: 30, marginLeft: "500px", marginTop: "200px", color:"blue" }}
            spin
          />
        }
      >
        <Outlet />
      </Suspense>
    </Content>
  );
}

export default AppContent;
