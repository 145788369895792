import HttpService from "./HttpService";

class AuthService extends HttpService {
  async login(payload) {
    return await this.sendRequest({
      method: "POST",
      url: "/auth/login",
      responseType: "json",
      data: payload,
    });
  }

  async whoAmI() {
    return await this.sendRequest({
      method: "GET",
      url: "/auth/whoAmI",
      responseType: "json",
    });
  }

  async resetPassword(payload) {
    return await this.sendResetLink({
      method: "POST",
      url: "/auth/reset-password",
      responseType: "json",
      data: payload,
    });
  }

  async forgetPassword(payload) {
    return await this.sendRequest({
      method: "POST",
      url: "/auth/forget-password",
      responseType: "json",
      data: payload,
    });
  }
}

const authService = new AuthService();
export default authService;
