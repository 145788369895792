import {
  UserOutlined,
  DashboardOutlined,
  TeamOutlined,
  FileAddOutlined,
  DiffOutlined,
} from "@ant-design/icons";

export const userNav = [
  {
    name: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    name: "Leave Management",
    to: "/leave-management",
    icon: <FileAddOutlined />,
  },
];

export const adminNav = [
  // {
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: <DashboardOutlined />,
  // },
  {
    name: "Employee",
    to: "/dashboard",
    icon: <UserOutlined />,
  },
  {
    name: "Teams",
    to: "/team",
    icon: <TeamOutlined />,
  },
  {
    name: "Leave Management",
    to: "/leave-management",
    icon: <DiffOutlined />,
  },
  {
    name: "Leave Type",
    to: "/leave-type",
    icon: <FileAddOutlined />,
  },
  {
    name: "Employee Type",
    to: "/employee-type",
    icon: <FileAddOutlined />,
  },
];
