import { notification } from "antd";
import { getMessaging, getToken } from "firebase/messaging";

export const loginhandler = {};

export const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: type,
    description: message,
  });
};

export const getBusinessDatesCount = (startDate, endDate, period) => {
  let d1 = new Date(startDate);
  let d2 = new Date(endDate);

  let count = 0;
  let curDate = +d1;
  while (curDate <= +d2) {
    const dayOfWeek = new Date(curDate).getDay();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    if (!isWeekend) {
      count++;
    }
    if (period == "morning half" || period == "evening half") {
      count = 0.5;
    }
    curDate = curDate + 24 * 60 * 60 * 1000;
  }
  return count;
};

export const getTeamsNames = (arr) => {
  const s = [];
  arr.map((x) => {
    const obj = {
      text: x.team.name,
      value: x.team.name,
    };
    s.push(obj);
  });
  const result = s.reduce((unique, o) => {
    if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
      unique.push(o);
    }
    return unique;
  }, []);

  return result;
};

export const receiveNotifications = async () => {
  const messaging = getMessaging();
  try {
    const b = await getToken(messaging, {
      vapidKey:
        "BKYaJiGlgmZtG-6xqCltUjsRfatQtPSNPcAXH8ur0yCP2NZjScpxrHww3WCNw5ZaT86AFvqx5DEc9RrW_T23-fs",
    });

    // getMessaging()
    //   .subscribeToTopic(b, topic)
    //   .then((response) => {
    //     // See the MessagingTopicManagementResponse reference documentation
    //     // for the contents of response.
    //     console.log("Successfully subscribed to topic:", response);
    //   })
    //   .catch((error) => {
    //     console.log("Error subscribing to topic:", error);
    //   });

    return b;
  } catch (error) {
    console.error(error);
  }
};
