import { LOGIN, LOGOUT } from "../../utils/reduxEnum";

export const userLogin = (payload) => {
  return {
    type: LOGIN,
    payload: payload,
  };
};

export const userLogout = () => {
  return {
    type: LOGOUT,
  };
};
