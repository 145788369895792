import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import DefaultLayout from "./layouts/DefaultLayout";

import Login from "./views/pages/login/Login";
import Resetpassword from "./views/pages/reset_password/Resetpassword";
import Forgetpassword from "./views/pages/reset_password/ForgetPassword";
import Page500 from "./views/pages/page500/Page500";
import Page404 from "./views/pages/page404/Page404";

import "antd/dist/antd.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { adminRoutes, userRoutes } from "./routes/routes";
import authService from "./services/AuthService";
import storageService from "./services/StorageService";

import { USER_ROLE } from "./utils/roleEnum";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { userLogin } from "./redux/actions";

function App() {
  const [loading, setloading] = useState(false);

  const token = storageService.getToken();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const auth = useSelector((state) => state);
  useEffect(() => {
    if (token) {
      whoAmI();
    }
  }, []);

  const whoAmI = () => {
    setloading(true);
    authService
      .whoAmI()
      .then((response) => {
        if (response) {
          dispatch(userLogin(response.data.user));
          setloading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        // openNotificationWithIcon("error", "Somthing went wrong!");
      });
  };
  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "40vh" }}>
          <LoadingOutlined style={{ fontSize: 35, color: "#FF5733" }} spin />
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/500" element={<Page500 />} />
            <Route
              path="/reset-password/:reset_token"
              element={<Resetpassword />}
            />
            <Route path="/forget-password" element={<Forgetpassword />} />
            <Route path="*" element={<Page404 />} />

            {auth?.isLoggedIn && (
              <Route element={<DefaultLayout />}>
                {auth?.user?.role == USER_ROLE.USER &&
                  userRoutes.map((route, idx) => (
                    <Route
                      key={idx}
                      path={route.path}
                      name={route.name}
                      element={<route.component />}
                    />
                  ))}
                {auth?.user?.role == USER_ROLE.ADMIN &&
                  adminRoutes.map((route, idx) => (
                    <Route
                      key={idx}
                      path={route.path}
                      name={route.name}
                      element={<route.component />}
                    />
                  ))}
              </Route>
            )}
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
