import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { openNotificationWithIcon } from "./utils/handler";

export const initializeFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBiI136KGcVNF3zg26ClrcbrNY_Au2zoas",
    authDomain: "hris-push-notification.firebaseapp.com",
    projectId: "hris-push-notification",
    storageBucket: "hris-push-notification.appspot.com",
    messagingSenderId: "1040898816340",
    appId: "1:1040898816340:web:204d2e09e252e392e2f0c1",
    measurementId: "G-QMEFD5XXDT",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  // const messagings = getMessaging();
  // onMessage(messagings, (payload) => {
  //   console.log("Message received. ", payload);
  //   openNotificationWithIcon("success", "Succefully created!");
  //   // ...
  // });
  // console.log("new On message from firebase.js", messagings);
};
