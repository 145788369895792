import React from "react";

// const Dashboard = React.lazy(() => import("../views/dashboard/Dashboard"));
const EmployeeDashboard = React.lazy(() =>
  import("../views/dashboard/EmployeeDashboard")
);
const Employee = React.lazy(() => import("../views/employee"));
const AddEmployee = React.lazy(() => import("../views/employee/AddEmployee"));
const LeaveRequest = React.lazy(() => import("../views/leaveRequest"));
const LeaveManagement = React.lazy(() =>
  import("../views/leaveManagement/LeaveManagement")
);
const LeaveType = React.lazy(() => import("../views/leaveType"));
const Team = React.lazy(() => import("../views/team"));
const AddTeamModal = React.lazy(() => import("../views/team/AddTeamModal"));
const EmplyeeType = React.lazy(() => import("../views/employeeType"));
const Page404 = React.lazy(() => import("../views/pages/page404/Page404"));
const EmployeeTab = React.lazy(() => import("../views/employee/EmployeeTab"));

export const adminRoutes = [
  // { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/dashboard", name: "Employee", component: Employee },
  { path: "/addEmployee", name: "AddEmployee", component: AddEmployee },
  {
    path: "/leave-management",
    name: "LeaveManagement",
    component: LeaveManagement,
  },
  { path: "/leave-type", name: "LeaveType", component: LeaveType },
  { path: "/team", name: "Teams", component: Team },
  { path: "/addTeam", name: "AddTeam", component: AddTeamModal },
  {
    path: "/dashboard/:employeeId",
    name: "EmployeeEdit",
    component: EmployeeTab,
  },
  {
    path: "/employee-type",
    name: "EmployeeType",
    component: EmplyeeType,
  },
  // {
  //   path: "*",
  //   name: "Page404",
  //   component: Page404,
  // },
];

export const userRoutes = [
  { path: "/dashboard", name: "Dashboard", component: EmployeeDashboard },
  {
    path: "/leave-management",
    name: "LeaveManagement",
    component: LeaveRequest,
  },
  // {
  //   path: "*",
  //   name: "Page404",
  //   component: Page404,
  // },
];
