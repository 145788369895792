import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { adminNav, userNav } from "../../routes/navLinks";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { USER_ROLE } from "../../utils/roleEnum";

const { Sider } = Layout;

function AppSidebar({ collapsed }) {
  const location = useLocation();

  const auth = useSelector((state) => state);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        {auth?.user?.role == USER_ROLE.USER &&
          userNav.map((navLink, index) => (
            <Menu.Item key={navLink.to} icon={navLink.icon}>
              <Link to={navLink.to}>{navLink.name}</Link>
            </Menu.Item>
          ))}
        {auth?.user?.role == USER_ROLE.ADMIN &&
          adminNav.map((navLink, index) => (
            <Menu.Item key={navLink.to} icon={navLink.icon}>
              <Link to={navLink.to}>{navLink.name}</Link>
            </Menu.Item>
          ))}
      </Menu>
    </Sider>
  );
}

export default AppSidebar;
