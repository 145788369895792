import axios from "axios";
import storageService from "./StorageService";

class HttpService {
  service = null;

  constructor() {
    this.service = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    this.service.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
  }

  sendRequest = (config) => {
    config.headers = {
      Authorization: `Bearer ${storageService.getToken()}`,
    };
    return this.service.request(config);
  };

  sendResetLink = (config) => {
    config.headers = {
      Authorization: `Bearer ${storageService.getSessionStorage()}`,
    };
    return this.service.request(config);
  };

  handleSuccess = (res) => {
    return res;
  };

  handleError = (e) => {
    if (!e.response) {
      return {
        data: {
          success: false,
          message: "Network Failure",
        },
      };
    }

    // throw e;
    // clear localstorage and redirect login (401 case)

    switch (e.response.status) {
      // case 502:
      //     window.location.replace('/500');
      //     break;
      case 401:
        localStorage.clear();
        window.location.replace("/");
        break;
      case 500:
        window.location.replace("/500");
        break;
      default:
        throw e;
    }
  };
}

export default HttpService;
