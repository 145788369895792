import React, { useState } from "react";
import { Layout, Button, Menu, Dropdown, Avatar } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LoginOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import AppSidebar from "../components/dashboard/AppSidebar";
import AppContent from "../components/dashboard/AppContent";

const { Header } = Layout;

function DefaultLayout({ children }) {
  const [state, setState] = useState({
    collapsed: false,
  });

  // const navigate = useNavigate();
  // const location = useLocation();

  const toggle = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };

  const Logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  // const menu = (
  //   <Menu>
  //     <Menu.Item>My Profile</Menu.Item>
  //     <Menu.Item>
  //       <a onClick={Logout}>Logout</a>
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <Layout className="site-layout-wrapper">
      <AppSidebar sidebarLinks={children} collapsed={state.collapsed} />
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
          {/* <div style={{ float: "right", marginRight: "20px" }}>
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                User 1
              </a>
            </Dropdown>
            <Avatar
              icon={<UserOutlined />}
              style={{ marginRight: "12px", marginLeft: "12px" }}
            />
            <DownOutlined />
          </div> */}

          <Button
            style={{ float: "right", marginTop: "15px", marginRight: "15px" }}
            type="link"
            onClick={Logout}
          >
            <LoginOutlined />
            Logout
          </Button>
        </Header>
        <AppContent />
      </Layout>
    </Layout>
  );
}

export default DefaultLayout;
