import { LOGIN, LOGOUT } from "../../utils/reduxEnum";

const authReducer = (state = { isLoggedIn: false, user: {} }, action) => {
  switch (action.type) {
    case LOGIN:
      return { isLoggedIn: true, user: action.payload };
    case LOGOUT:
      return { isLoggedIn: false, user: {} };
    default:
      return state;
  }
};

export default authReducer;
