import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import authService from "../../../services/AuthService";
import storageService from "../../../services/StorageService";
import { useNavigate, useParams } from "react-router-dom";
import { openNotificationWithIcon } from "../../../utils/handler";

function Resetpassword() {
  const [loading, setLoading] = useState(false);
  const { reset_token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    storageService.setSessionStorage(reset_token);
  }, []);

  const onFinish = (values) => {
    const payload = {
      new_password: values.password,
    };

    setLoading(true);
    authService
      .resetPassword(payload)
      .then((response) => {
        if (response) {
          storageService.removeSessionStorage();
          setLoading(false);
          navigate("/");
        }
      })
      .catch((error) => {
        openNotificationWithIcon("error", error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="normal-login-wrapper">
      <div className="page-normal-login">
        <Form
          name="normal_login"
          className="forget-pass-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="password"
            tooltip="This is a required field"
            label="Please enter your new password"
            rules={[
              {
                required: true,
                message: "Please enter your Password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={loading}
            >
              {loading && <LoadingOutlined style={{ fontSize: 16 }} spin />}
              Reset password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Resetpassword;
