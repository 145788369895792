import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
function Page404() {
  const navigate = useNavigate();
  return (
    <Result
      style={{ alignContent: "center", marginTop: "55px" }}
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button
        onClick={() => {
          navigate(`/dashboard`);
        }}
         type="primary">Back Home</Button>}
    />
  );
}

export default Page404;
