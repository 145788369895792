class StorageService {
  setToken(token) {
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  setLoggedIn(value) {
    localStorage.setItem("isLoggedIn", value);
  }

  getIsLoggedIn() {
    return localStorage.getItem("isLoggedIn");
  }

  removeLocalStorage() {
    return localStorage.clear();
  }

  setSessionStorage(token) {
    return sessionStorage.setItem("token", token);
  }

  getSessionStorage() {
    return sessionStorage.getItem("token");
  }

  removeSessionStorage() {
    return sessionStorage.clear();
  }
}

const storageService = new StorageService();
export default storageService;
