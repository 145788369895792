
import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
function Page500() {
  const navigate = useNavigate();
  return (
    <div>
      <Result
       style={{ alignContent: "center", marginTop:"55px" }}
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigate(`/dashboard`);
            }}
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
}

export default Page500;