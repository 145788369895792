import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import authService from "../../../services/AuthService";
import storageService from "../../../services/StorageService";
import notificationService from "../../../services/NotificationService";
import { useDispatch } from "react-redux";
import { userLogin } from "../../../redux/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo_Login from "../../../assets/logo_login.png";
// import AuthContext from "../../../context/AuthProvider";
import {
  openNotificationWithIcon,
  receiveNotifications,
} from "../../../utils/handler";

function Login() {
  const auth = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deviceToken, setDeviceToken] = useState("");

  useEffect(() => {
    if (auth?.isLoggedIn) {
      navigate("/dashboard");
    }
  }, [auth]);

  const notificationHandler = async () => {
    const device_token = await receiveNotifications();
    console.log("ssssssssssssssssssssss", device_token);
    if (device_token) {
      notificationService
        .updateDeviceToken({ device_token: device_token })
        .then((response) => {
          if (response?.data) {
          }
        })
        .catch((error) => {
          openNotificationWithIcon("error", error.response.data.message);
          setLoading(false);
        });
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    authService
      .login(values)
      .then((response) => {
        if (response?.data) {
          storageService.setToken(response.data.tokens.token);
          dispatch(userLogin(response.data.user));
          navigate("/dashboard");
          setLoading(false);
          notificationHandler();
        }
      })
      .catch((error) => {
        openNotificationWithIcon("error", error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="normal-login-wrapper">
      <div className="login-wrapper-inner">
        <img src={logo_Login} alt="logo" width="50px"></img>
        <p>Let's get started now!</p>
      </div>
      <div className="page-normal-login">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Button
            className="login-form-forgot"
            type="link"
            onClick={() => navigate(`/forget-password`)}
          >
            Forgot password
          </Button>

          {/* <Form.Item>
            <a  href="">
              Forgot password
            </a>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={loading}
            >
              {loading && <LoadingOutlined style={{ fontSize: 16 }} spin />}Log
              in
            </Button>
            {/* Or <a href="">register now!</a> */}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
